<template>
    <div>
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
            >
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>personaliza tu logo y tu firmas</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialog = false">Guardar</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                cols="2"
                                sm="2"
                                >
                                    <v-img
                                        :src="url"
                                    ></v-img>
                                </v-col>
                                <v-col cols="10">
                                    <v-file-input
                                    accept="image/png"
                                    placeholder="Formato .png (50x50)"
                                    prepend-icon="mdi-camera"
                                    label="Logo"
                                    type="file"
                                    @change="fileSelected($event)"
                                    ></v-file-input>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-card
                                        class="mx-auto"
                                        max-width="344"
                                    >
                                        <v-img
                                        src="https://upload.wikimedia.org/wikipedia/commons/1/11/Firma_de_Juan_Jos%C3%A9_Pradera.svg"
                                        height="200px"
                                        ></v-img>

                                        <v-card-title class="align-left">
                                        Jose de jesus
                                        </v-card-title>

                                        <v-card-subtitle class="align-left">
                                        Desarrollador web
                                        </v-card-subtitle>

                                        <v-card-actions>
                                        <v-btn
                                            color="orange lighten-2"
                                            text
                                        >
                                            ver mas
                                        </v-btn>

                                        <v-spacer></v-spacer>

                                        <v-btn
                                            icon
                                            @click="show = !show"
                                        >
                                            <v-icon>
                                                {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                            </v-icon>
                                        </v-btn>
                                        </v-card-actions>

                                        <v-expand-transition>
                                        <div v-show="show">
                                            <v-divider></v-divider>

                                            <v-card-text>
                                                <v-text-field
                                                v-model="nombre"
                                                color="purple darken-2"
                                                label="Nombre"
                                                required
                                                ></v-text-field>
                                                <v-text-field
                                                v-model="cargo"
                                                color="purple darken-2"
                                                label="Cargo"
                                                required
                                                ></v-text-field>
                                                <v-text-field
                                                v-model="correo"
                                                color="purple darken-2"
                                                label="Correo"
                                                required
                                                ></v-text-field>
                                                <v-text-field
                                                v-model="telefono"
                                                color="purple darken-2"
                                                label="Telefono"
                                                required
                                                ></v-text-field>
                                                <v-btn
                                                color="success"
                                                class="mr-4"
                                                >
                                                Guardar
                                                </v-btn>
                                            </v-card-text>
                                        </div>
                                        </v-expand-transition>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
// import { mapState, mapMutations } from 'vuex';
// import Service from '../../services/apis';

export default {
  data: () => ({
    dialog: false,
    show: false,
    url: 'img/TxA-logo.svg',
  }),
  methods: {
    // ...mapMutations('Ensayos', ['setMix']),
    openmodal() {
      this.dialog = true;
    },
    fileSelected(event) {
      if (event) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.url = e.target.result;
        };
        reader.readAsDataURL(event);
      }
    },
  },
};
</script>
